var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.clients},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-toolbar-title',[_vm._v("Saldos Vencidos")]),_c('v-icon',{staticClass:"ml-4",attrs:{"medium":""},on:{"click":function($event){_vm.filter=!_vm.filter}}},[_vm._v(" mdi-filter")]),_c('v-spacer'),(_vm.filter)?_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.userLists,"label":"Usuario","item-text":"name","item-value":"id"},model:{value:(_vm.salesmanFilterValue),callback:function ($$v) {_vm.salesmanFilterValue=$$v},expression:"salesmanFilterValue"}},[_c('template',{staticClass:"pa-2",slot:"no-data"},[_vm._v("No existen usuarios relacionados.")])],2):_vm._e(),(_vm.filter)?_c('v-btn',{staticClass:"elevation-0 ml-4",attrs:{"small":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.filterNow()}}},[_vm._v("Filtrar")]):_vm._e()],1)],1)]},proxy:true},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"min-height":"0px!important","font-size":"14px"},attrs:{"to":{ path: '/clients/client/'+ item.client.id}}},[_vm._v(_vm._s(item.client.name))])]}},{key:"item.cero",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cero.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.treinta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.treinta.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.sesenta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sesenta.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.noventa",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.noventa.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.total).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"footer",fn:function(){return [_c('v-container',{staticStyle:{"margin-bottom":"-80px!important"}},[_c('v-select',{staticStyle:{"width":"100px","display":"inline-block","margin-right":"30px","font-size":"14px"},attrs:{"items":_vm.sumavg},model:{value:(_vm.totalType),callback:function ($$v) {_vm.totalType=$$v},expression:"totalType"}}),_c('v-select',{staticStyle:{"width":"100px","display":"inline-block","margin-right":"30px","font-size":"14px"},attrs:{"items":_vm.campos,"item-text":"text","item-value":"value"},model:{value:(_vm.campo),callback:function ($$v) {_vm.campo=$$v},expression:"campo"}}),(_vm.totalType=='sum')?_c('strong',[_vm._v("Total = "+_vm._s(_vm.sumField(_vm.campo)))]):_vm._e(),(_vm.totalType=='avg')?_c('strong',[_vm._v("Promedio = "+_vm._s(_vm.avgField(_vm.campo)))]):_vm._e()],1)]},proxy:true}],null,true)})],1):_c('v-container',{staticClass:"px-12 mt-12"},[_c('v-progress-linear',{staticClass:"mt-12",attrs:{"color":"primary","indeterminate":"","rounded":"","height":"6"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }